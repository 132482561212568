.contacts {
  width: 300px;
  margin: 0 auto;
}
.search-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.contacts-list {
  padding: 0;
  width: 100%;
}

.contact {
  display: flex;
  align-items: center;
  font-family: sans-serif;
  width: 100%;
  padding: 5px;
  border-bottom: 1px dotted grey;
}
.contact-image {
  margin: 5px;
}
.contact-name {
  font-size: 20px;
  font-weight: bold;
}
.contact-number {
  font-size: 18px;
  color: grey;
}
